import React from 'react'
import Laboratory from "../images/laboratory.jpeg";
import Factory from "../images/factory.jpeg";
import Outdoorphoto from "../images/outdoorphoto.jpeg";

function Sliderbrick() {
  return (
    <div>
        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={Laboratory} className="d-block w-100" alt="laboratory_Rupa_Eco_Brick"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Rupa Eco Brick</h5>
        <p> laboratory Room </p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={Factory} className="d-block w-100" alt="Factory-Rupa-Eco-Brick"/>
      <div className="carousel-caption d-none d-md-block">
        <h5 style={{color:"white"}} >Rupa Eco Brick</h5>
        <p  style={{color:"white"}}> Factory Gallary</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={Outdoorphoto} className="d-block w-100" alt="Outdoorphoto"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Rupa Eco Brick</h5>
        <p>Outdoor Photo </p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderbrick