import React from 'react'
import MAINPAGEruparcobrick from "../images/main_banner.jpg";
import bannarrupaecobrick from "../images/bannerrupaecobrick.jpg";
import Paver from "../images/paver.jpg";
import Rupatiles from "../images/rupatiles.jpg";
import Rupaecob from "../images/rupaecob.jpg";
import {Link} from "react-router-dom";

function Slider() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={MAINPAGEruparcobrick} className="d-block w-100" alt="MAINPAGEruparcobrick"/>
    </div>
    <div className="carousel-item">
      <img src={bannarrupaecobrick} className="d-block w-100" alt="bannarrupaecobrick"/>
    </div>
    <div className="carousel-item">
    <Link to="/paver">
      <img src={Paver} className="d-block w-100" alt="Paver"/>
      </Link>
    </div>
    <div className="carousel-item">
      <Link to="/tiles">
      <img src={Rupatiles} className="d-block w-100" alt="Rupatiles"/>
      </Link>
    </div>
    <div className="carousel-item">
      <Link to="/brick">
      <img src={Rupaecob} className="d-block w-100" alt="Rupatiles"/>
      </Link>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider
