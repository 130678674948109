import React from 'react'
import RCCGARDENBENCH from "../images/rccgarden.jpg";
import rccgardenbench from "../images/CONCRETEbench.jpg";
import {Link} from "react-router-dom";
import Navbar from "../inc/Navbar";
function Bench() {
  return (
    <div>
    <Navbar/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
       <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                            <Link to="/gardenbench">
                        <img src={RCCGARDENBENCH} className="card-img-top"   alt="RCCGARDENBENCH" /></Link>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b> RCC GARDEN BENCH</b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> ₹8,500</p>


<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>

                    
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="/gardenbench">
                                <center>
 
 <button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

             </center> 
     </Link>
                            </div>
                            </div>
                            </div>
                       
                            </div>
                  
                            <div className="col-md-6 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                            <Link to="/Concretebench">
                        <img src={rccgardenbench} className="card-img-top"   alt="rccgardenbench" /></Link>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>RCC CENCRETE BENCH</b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> ₹3,5000</p>


<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>

                    
                                <div className="d-flex justify-content-between align-items-center">
                                  <Link to="/Concretebench">
                                <center>
 
 <button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

</center>

</Link>
                                        
                            </div>
                            </div>
                            </div>
                       
                            </div>
                            </div>
                            </div>
                            </div>

    </div>
  );
}

export default Bench;
;