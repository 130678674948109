import React from 'react'
import Block120 from "../images/blockproject.jpeg";
import Block120MM from "../images/block120.jpeg";
import Block120mmberhampore from "../images/block120mm.jpeg";
import Blocksupply from "../images/blocksupplymurshidabad.jpeg";

function Sliderblock() {
  return (
    <div>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

  </div>

  
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Block120} className="d-block w-100" alt="block120mm"/>
    </div>
    <div className="carousel-item">
      <img src={Block120MM} className="d-block w-100" alt="blockberhampore"/>
    </div>
    <div className="carousel-item">
      <img src={Block120mmberhampore} className="d-block w-100" alt="Block120mmberhampore"/>
    </div>
    <div className="carousel-item">
      <img src={Blocksupply} className="d-block w-100" alt="blockberhampore"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderblock