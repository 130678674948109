import React, {useState, useEffect} from "react";

const Pagination = ({showPerPage, onPaginationChange, total }) => {
   const[counter, setCounter] = useState(1);

   useEffect(() => {
       const value = showPerPage * counter;
       console.log("start value: ", value - showPerPage);
       console.log("end value:", value);
       onPaginationChange( value - showPerPage, value);
   },[counter]);

   const onButtonClick = (type) => {
      if (type === "prev") {
          if(counter === 1){
            setCounter(1);
          } else {
              setCounter(counter - 1);
          }
      } else if (type === "next") {
          if (Math.ceil(total / showPerPage) === counter){
              setCounter(counter);
          } else {
            setCounter(counter+1);
          }
            
      }
   };
    return (
        <>   
         <div className="d-flex justify-content-between">
             
        <button className="btn btn-outline-primary"
        onClick={() => onButtonClick("prev")}
        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
      </svg> </button>
        <button className="btn btn-outline-primary"
         onClick={() => onButtonClick("next")}
         ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
         <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
       </svg></button>
    </div>
    </>

    );
};

export default Pagination;