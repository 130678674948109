import React from 'react'
import Main2 from "../images/MAINPAGE.jpg";
import Heading from "../images/head2.jpg";

function Slider() {
  return (
        <div>

<div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={Main2} className="d-block w-100" alt="Main"/>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={Heading} className="d-block w-100" alt="Heading"/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
        </div>
  )
}

export default Slider