import React from 'react'
import Navbar from '../inc/Navbar'
import bis from "../images/BIS CERTIFICATE.jpg"
import iso from "../images/ISO 9001.2015 upto 2026.jpg";
import acccertificate from "../images/ACC GBC Certificate.jpg";
import ScrollToTop from "react-scroll-to-top";

import {Link} from "react-router-dom";
import Sliderbrick from '../inc/Sliderbrick';

function Certificate() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
           
          
            <br/>
            <ScrollToTop smooth top="100" color="green"/>
            <Sliderbrick/>
            <br />
           
            <div className='album py-1'>
                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{ color: "green" }} className="breadcrumb-item"><Link to="/" style={{ color: "green" }} >Home</Link></li>
                            <li style={{ color: "green" }} className="breadcrumb-item"><Link to="/contact" style={{ color: "green" }} href="#">Contact</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Certificate <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card text-dark bg-white mb-3" >
                                <div style={{color:"green"}} className="card-header"><center>BIS CERTIFICATIONS</center></div>
                                <div className="card-body">
                                    <img src={bis} className="card-img-top mb-2" alt='biscertificate'/>
                                    <center>
                                    <p className="card-text">Rupa Eco Brick BIS CERTIFICATE</p>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card text-dark bg-white mb-3" >
                                <div style={{color:"green"}}  className="card-header"><center>ISO CERTIFICATE</center></div>
                                <div className="card-body">
                                <img src={iso} className="card-img-top mb-3" alt='iso-certificate'/>
                                <center>
                                    <p className="card-text">Repa Eco Brick ISO 9001:2015 </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card text-dark bg-white mb-3" >
                                <div style={{color:"green"}}  className="card-header"><center>ACC CERTIFICATE</center></div>
                                <div className="card-body">
                                <img src={acccertificate} className="card-img-top mb-3" alt='acccertificate'/>
                                <center>
                                    <p className="card-text">Repa Eco Brick ACC CERTIFICATE</p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<br/>
<br/>
        </div>
    )
}

export default Certificate