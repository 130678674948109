
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyD15Zp9OTORO7HIwNcJRo_k57Eq28WF5JY",
  authDomain: "rupa-eco-brick-bf469.firebaseapp.com",
  projectId: "rupa-eco-brick-bf469",
  storageBucket: "rupa-eco-brick-bf469.appspot.com",
  messagingSenderId: "569061220677",
  appId: "1:569061220677:web:6566d76b46a32b9f51de2c",
  measurementId: "G-XMBG18G4TM"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;