import React from 'react';


function Map() {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
 <iframe className="iframe-fluid"  width="1100" height="436" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%20+(Rupa%20Eco%20Brick,%2026JX+84J,%20Murshidabad,%20West%20Bengal%20742134)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>


</div>

</div>
</div>
 

</>
);
}

export default Map;