import React from 'react'
import Paverzig from "../images/paver-zig-zig.jpg";
import Paverclo from "../images/paver-clo.jpg";
import Damro from "../images/damro.jpg";
import Arrowpaver from "../images/arrowpaver.jpg";
import GrassLive from "../images/Grass-Live.jpg";
import PAVERCOBBLESTONEIVrupaecobrick from "../images/PAVERCOBBLESTONEIV.jpg";
import PAVERCOMBODIAMONDrupaecobrick from "../images/PAVERCOMBODIAMOND.jpg";
import PAVERCOMBOHEXArupaecobrick2 from "../images/PAVERCOMBOHEXA.jpg";
import PAVERDIAMONDrupaecobrick from "../images/cobblestone.jpg";
import PAVERHEXArupaecobrick from "../images/PAVERHEXA.jpg";
import Arrow60mm from "../images/arrow.jpg";
import paverrecte from "../images/recta.jpg";

import ScrollToTop from "react-scroll-to-top";
import { 
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
     FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,
     LinkedinShareButton,
     LinkedinIcon,
     TwitterIcon,
  }  from "react-share";

import Navbar from "../inc/Navbar";
import {Link} from "react-router-dom";

function Paverall() {
  return (
    <div>

        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div style={{color: "white", padding:"14px;"}} className="fixed-bottom "> <a href="tel: +91 9434102089" className="btn btn-primary" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div> </a></div>
<ScrollToTop smooth top="100" color="Gray"/>
      <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                            <Link to="/zigzag">
                        <img src={Paverzig} className="card-img-top"   alt="Paverzig" /></Link>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b> Zig Zag</b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Thickness - 60 MM</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 288 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Yellow, Maroon</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/zigzag" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/zigzag" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/zigzag" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/zigzag" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/zigzag" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                       
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                            <Link to="/colorado">
                        <img src={Paverclo} className="card-img-top"   alt="Paverclo" /></Link>
                            <div className="card-body"> 
                                <h6 className="card-text mb-2"><b> Colorado </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 224 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Yellow, Maroon, Blue</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/colorado" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/colorado" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/colorado" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/colorado" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/colorado" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/damaru">
                        <img src={Damro} className="card-img-top"   alt="Damro" /></Link>
                            <div className="card-body">
                                <h6  className="card-text"><b> Damaru</b></h6>
                                <p   className="card-text mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 240 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Yellow, Maroon, Gray, Blue</p>

<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/damaru" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/damaru" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/damaru" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="hhttps://www.rupaecobrick.com/damaru" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/damaru" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                         
                                <div className="d-flex justify-content-between align-items-center">
                                                       
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/diamond" target="_blank" rel="noopener noreferrer">
                        <img src={Arrowpaver} className="card-img-top"   alt="Arrowpaver" /></Link>
                            <div className="card-body"> 
                                <h6 className="card-text"><b>  Diamond </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹50.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 285 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Maroon, Gray</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        <FacebookShareButton url="https://www.rupaecobrick.com/arrow" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
    </FacebookShareButton>

       <WhatsappShareButton url="https://www.rupaecobrick.comarrow/" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}>
      <WhatsappIcon size={37} round={true}/>
     </ WhatsappShareButton>

                              <TwitterShareButton url="https://www.rupaecobrick.com/arrow" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}>
     <TwitterIcon size={37} round={true}/>
   </TwitterShareButton>  
    <LinkedinShareButton url="https://www.rupaecobrick.com/arrow" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}>
    <LinkedinIcon size={37} round={true}/>
    </ LinkedinShareButton>

        <EmailShareButton url="https://www.rupaecobrick.com/arrow" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}>
        <EmailIcon size={37} round={true}/>
          </ EmailShareButton>

                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/grass" target="_blank" rel="noopener noreferrer">
                        <img src={GrassLive} className="card-img-top"   alt="GrassLive" />
                        </Link>
                            <div className="card-body"> 
                                <h6 className="card-text"><b> Grass</b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Maroon </p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                             <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/stonefinish" target="_blank" rel="noopener noreferrer">
                        <img src={PAVERCOBBLESTONEIVrupaecobrick} className="card-img-top"   alt="PAVERCOBBLESTONErupa" /></Link>
                            <div className="card-body"> 
                              
                                <h6 className="card-text"><b>  Stone Finish </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 200 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Gray</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
     </ EmailShareButton>

                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                    
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/diamondarrow" target="_blank" rel="noopener noreferrer">
                        <img src={PAVERCOMBODIAMONDrupaecobrick} className="card-img-top"   alt="PAVERCOMBODIAMONDrupaecobrick" /></Link>
                            <div className="card-body"> 
                                <h6 className="card-text mb-2"><b>  Combo Diamond & Arrow </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹50.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 360 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Red, Gray & Black</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/hexa" target="_blank" rel="noopener noreferrer">
                        <img src={PAVERCOMBOHEXArupaecobrick2} className="card-img-top"   alt="PAVERCOMBOHEXArupaecobrick2" /></Link>
                            <div className="card-body"> 
                                <h6 className="card-text "><b>  Combo Hexa & Y </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹50.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - Hexa 85 & Y 170 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Black & Maroon</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/hexagon" target="_blank" rel="noopener noreferrer">
                        <img src={PAVERHEXArupaecobrick} className="card-img-top"   alt="PAVERHEXArupaecobrick" /></Link>
                            <div className="card-body"> 
                                <h6 className="card-text"><b> Hexagon</b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹45.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 139 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: White & Red</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/cobblestone" target="_blank" rel="noopener noreferrer">
                        <img src={PAVERDIAMONDrupaecobrick} className="card-img-top"   alt="PAVERDIAMONDrupaecobrick" />
                        </Link>
                            <div className="card-body"> 
                                <h6 className="card-text"><b>Cobble Stone </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹50.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 400 No's/100 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Maroon, Gray</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                           
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <a href="/Arrow" >
                        <img src={Arrow60mm} className="card-img-top"   alt="arrow60mm" />
                        </a>
                            <div className="card-body"> 
                                <h6 className="card-text"><b>Arrow  </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  ₹50.00</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 40, 50, 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 0.278 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Maroon, Gray, White</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/Arrow" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/Arrow" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/Arrow" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/Arrow" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/Arrow" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <a href="/Recta" >
                        <img src={paverrecte} className="card-img-top"   alt="paver-recte" />
                        </a>
                            <div className="card-body"> 
                                <h6 className="card-text"><b>Brick Recta </b></h6>
                                <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Rs. 45.00/SQFT for  non Reflective or Rs. 50.00/SQFT Reflective</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Thickness - 40, 50, 60 MM</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Grade - M-35</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  Area - 0.222 Sqft</p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> Color: Maroon, Gray, White</p>
<p style={{color: "Gray"}} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Rupa Eco Brick</p>
<button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.rupaecobrick.com/Recta" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.rupaecobrick.com/Recta" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.rupaecobrick.com/Recta" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.rupaecobrick.com/Recta" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.rupaecobrick.com/Recta" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                          <div className="d-flex justify-content-between align-items-center">                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                  
                           

                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Paverall
