
import React from "react";
import Navbar from "../inc/Navbar";


import Accupressuretiles from "../images/Accupressure.jpg";
import Tiles16sq from "../images/Tiles16Square.jpg"
import tiles9Square from "../images/9Square.jpg"
import BigOtiles from "../images/BigO.jpg"
import Cadburytiles from "../images/cadbury.jpg"
import Chathuratiles from "../images/Chathura.jpg"
import Tilesdoller from "../images/Doller.jpg"
import Tilescadburybit from "../images/Cadburybit.jpg"
import Galicha from "../images/galicha.jpg";
import TilesWave from "../images/TILESWAVE.jpg";

import ScrollToTop from "react-scroll-to-top";
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

import { Link } from "react-router-dom";

function Tilespage() {
  return (
    <>

      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style={{ color: "white", padding: "14px;" }} className="fixed-bottom "> <a href="tel: +91 9434102089" className="btn btn-primary" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div> </a></div>
      <ScrollToTop smooth top="100" color="Gray" />
      <div className="album py-4">

        <div className="container">

          <div className="row">

            <div className="col-md-4 mt-8" >
              <div className="card mb-4 border-White box shadow">
                <Link to="/acupressure">
                  <img src={Accupressuretiles} className="card-img-top" alt="Accupressuretiles" /></Link>
                <div className="card-body">

                  <h6 className="card-text"> <b> Tiles Accupressure 12"X12"</b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Grade - M-35</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Size - 12"X12"X25 MM</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color: Yellow, Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/acupressure" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/acupressure" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/acupressure" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/acupressure" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/acupressure" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>

                  <div className="d-flex justify-content-between align-items-center">

                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/square16">
                  <img src={Tiles16sq} className="card-img-top" alt="Tiles16Tiles16sq" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles 16 Square 10"X10" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹21.00</p>



                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color:  Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/square16" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/square16" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/square16" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/square16" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/square16" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/square9">
                  <img src={tiles9Square} className="card-img-top" alt="tiles9Square" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles 9 Square 10"X10</b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹21.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color:  Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/square9" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/square9" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/square9" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/square9" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/square9" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/bigO">
                  <img src={BigOtiles} className="card-img-top" alt="BigOtiles" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Big O 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color:  Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/bigO" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/bigO" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/bigO" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/bigO" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/bigO" className="me-2" quote={"best paver in berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/cadbury">
                  <img src={Cadburytiles} className="card-img-top" alt="Cadburytiles" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Cadbury 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color: Gray, Blue</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/cadbury" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/cadbury" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/cadbury" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/cadbury" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/cadbury" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/chathura">
                  <img src={Chathuratiles} className="card-img-top" alt="Chathuratiles" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Chathura 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹32.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color:  Maroon, Yellow, Black</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/chathura" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/chathura" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/chathura" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/chathura" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/chathura" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/doller">
                  <img src={Tilesdoller} className="card-img-top" alt="Tilesdoller" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Doller 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Grade - M-35</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Size - 12"X12"X25 MM</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color:  Maroon, Yellow</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/doller" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/doller" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/doller" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/doller" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/doller" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/cadburybit">
                  <img src={Tilescadburybit} className="card-img-top" alt="Tilescadburybit" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Cadbury bit 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color: Black, Gray</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/cadburybit" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/cadburybit" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/cadburybit" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/cadburybit" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/cadburybit" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>

            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/Galicha">
                  <img src={Galicha} className="card-img-top" alt="Galicha" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Galicha 12"X12" </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹28.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Grade - M-35</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color: Yellow, Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/Galicha" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/Galicha" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/Galicha" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/Galicha" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/Galicha" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>

            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/wave">
                  <img src={TilesWave} className="card-img-top" alt="Tiles-Wave" /></Link>
                <div className="card-body">
                  <h6 className="card-text mb-2"><b> Tiles Wave </b></h6>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  ₹21.00</p>

                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>  Grade - M-35</p>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg> Color: Yellow, Maroon</p>
                  <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>  Available in Rupa Eco Brick</p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.rupaecobrick.com/wave" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.rupaecobrick.com/wave" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.rupaecobrick.com/wave" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.rupaecobrick.com/wave" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.rupaecobrick.com/wave" className="me-2" quote={"Best Tiles in Berhampore"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tilespage;