
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./components/pages/Main";
import Brick from "./components/pages/Brick";
import Paverall from "./components/pages/Paverall";
import Others from "./components/pages/Others";
import Distibutors from "./components/pages/Distibutors";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Work from "./components/pages/Work";
import Zigzagpage from "./components/pages/Zigzagpage";
import Colorado from "./components/pages/Colorado";
import Damaru from "./components/pages/Damaru";
import Arrow from "./components/pages/Arrow";
import Cobble from "./components/pages/Cobble";
import Grass from "./components/pages/Grass";
import Diamondarrow from "./components/pages/Diamondarrow";
import Hexa from "./components/pages/Hexa";
import Hexagon from "./components/pages/Hexagon";
import Stonefinish from "./components/pages/Stonefinish";
import Footer from "./components/inc/Footer";
import Brickpage from "./components/pages/Brickpage";


import Benchpage from "./components/pages/Benchpage";
import Tilespage from "./components/pages/Tilespage";
import Concretebench from "./components/pages/Concretebench";
import Acupressure from "./components/pages/Acupressure";
import Square16 from "./components/pages/Square16";
import Square9 from "./components/pages/Square9";
import BigO from "./components/pages/BigO";
import Cadbury from "./components/pages/Cadbury";
import Chathura from "./components/pages/Chathura";
import Doller from "./components/pages/Doller";
import Cadburybit from "./components/pages/Cadburybit";
import Block from './components/pages/Block';
import Certificate from './components/pages/Certificate';
import Block120 from './components/pages/Block120';
import Arrow60 from './components/pages/Arrow60';
import Recta from './components/pages/Recta';
import Galicha from './components/pages/Galicha';
import Wave from './components/pages/Wave';
import Other from './components/pages/Other';



function App() {
  return (
      
    <Router>
      
  
     
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Main/>}/>
       
          <Route axact path="/brick" element={<Brick/>}/>
          <Route axact path="/tiles" element={<Tilespage/>}/>
         
          <Route axact path="/gardenbench" element={<Others/>}/>
          <Route axact path="/distributor" element={<Distibutors/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/about" element={<About/>}/>
          <Route axact path="/paver" element={<Paverall/>}/>
          <Route axact path="/work" element={<Work/>}/>
          <Route axact path="/zigzag" element={<Zigzagpage/>}/>
          <Route axact path="/colorado" element={<Colorado/>}/>
          <Route axact path="/diamond" element={<Arrow/>}/>
          <Route axact path="/cobblestone" element={<Cobble/>}/>
          <Route axact path="/grass" element={<Grass/>}/>
          <Route axact path="/diamondarrow" element={<Diamondarrow/>}/>
          <Route axact path="/hexa" element={<Hexa/>}/>
            <Route axact path="/hexagon" element={<Hexagon/>}/>
            <Route axact path="/stonefinish" element={<Stonefinish/>}/>
          <Route axact path="/damaru" element={<Damaru/>}/>
          <Route axact path="/brickpage" element={<Brickpage/>}/>
          <Route axact path="/benchpage" element={<Benchpage/>}/>
            <Route axact path="/concretebench" element={<Concretebench/>}/>
            <Route axact path="/acupressure" element={<Acupressure/>}/>
            <Route axact path="/square16" element={<Square16/>}/>
            <Route axact path="/square9" element={<Square9/>}/>
            <Route axact path="/bigO" element={<BigO/>}/>
            <Route axact path="/cadbury" element={<Cadbury/>}/>
            <Route axact path="/chathura" element={<Chathura/>}/>
            <Route axact path="/doller" element={<Doller/>}/>
            <Route axact path="/cadburybit" element={<Cadburybit/>}/>
            <Route axact path="/block" element={<Block/>}/>
            <Route axact path="/Certificate" element={<Certificate/>}/>
            <Route axact path="/Block120" element={<Block120/>}/>
            <Route axact path="/Arrow" element={<Arrow60/>}/>
            <Route axact path="/Recta" element={<Recta/>}/>
            <Route axact path="/Galicha" element={<Galicha/>}/>
            <Route axact path="/wave" element={<Wave/>}/>
            <Route axact path="/Others" element={<Other/>}/>
         
             
          </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
