import React from "react";
import Navbar from "../inc/Navbar";
import AddDistributor from '../AddDistributor';
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";
function Distributors (){
    return(
            <>
            <Navbar/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="album py-1">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link to="/" >Home</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link to="/paver" >Paver</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link to="/tiles" >Tiles</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link to="/brick" >Brick</Link></li>

    <li  className="breadcrumb-item active" aria-current="page">Distributor</li>
    </ol>
    </nav>
     </div>
       </div>
       </div>
                <ScrollToTop smooth top="100" color="Darkblue"/>
            <AddDistributor/>
            <br/>
            </>
    );
}

export default Distributors;